<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          style="margin-right: 5px"
          @click="getList()"
        ></el-button>
        <el-input
          size="small"
          placeholder="请输入客户编码/客户名称/合同名称"
          v-model.trim="listQuery.name"
          style="width: 200px"
          @keyup.enter="getList"
          clearable
        ></el-input>
        <el-button
          size="small"
          type="primary"
          @click="getList"
          style="margin-right: 10px"
          icon="Search"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form
            style="width: 550px"
            label-width="90px"
            size="small"
            inline="true"
            class="style_form"
          >
            <el-form-item label="开始日期：">
              <el-date-picker
                style="width: 150px"
                v-model="listQuery.beginTime"
                type="date"
                placeholder="起始日期"
                clearable
                value-format="YYYY-MM-DD"
              />
              &nbsp;-&nbsp;
              <el-date-picker
                style="width: 150px"
                v-model="listQuery.endTime"
                type="date"
                placeholder="终止日期"
                clearable
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
            <el-form-item label="合同状态：">
              <select-common
                placeholder="请选择合同状态"
                v-model:modelValue="listQuery.contractState"
                :options="statusOptions"
              />
            </el-form-item>
            <el-form-item label="收款状态：">
              <el-checkbox-group v-model="listQuery.allStatus">
                <el-checkbox
                  v-for="item in chargeOptions"
                  :key="item.value"
                  :label="item.value"
                ></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="合同编码：">
              <el-input
                v-model="listQuery.htbm"
                placeholder="请输入合同编码"
              ></el-input>
            </el-form-item>
            <el-form-item label="客户：">
              <el-input
                v-model="listQuery.htbm"
                placeholder="请输入编号/名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="业务类型：">
              <el-input
                v-model="listQuery.htbm"
                placeholder="请输入编号/名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="部门：">
              <el-input
                v-model="listQuery.htbm"
                placeholder="请输入编号/名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否签约：">
              <el-input
                v-model="listQuery.htbm"
                placeholder="请输入编号/名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否续签：">
              <el-input
                v-model="listQuery.htbm"
                placeholder="请输入编号/名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="服务方式：">
              <select-common
                placeholder="请选择服务方式"
                v-model:modelValue="listQuery.contractState"
                :options="serviceOptions"
              />
            </el-form-item>
            <el-form-item label="审核人：">
              <el-input
                v-model="listQuery.htbm"
                placeholder="请输入编号/名称"
              ></el-input>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div>
        <el-button size="small" type="primary" icon="Plus">新增</el-button>
        <el-button size="small" type="danger" plain icon="Delete"
          >删除</el-button
        >
        <el-button size="small" type="primary" icon="Position"
          >提交审核</el-button
        >
        <el-button size="small" type="primary" icon="Folder">导出</el-button>
        <el-button size="small" type="success" icon="Printer">打印</el-button>
        <el-dropdown trigger="click">
          <el-button type="primary" size="small" class="ml_12">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>撤销提交</el-dropdown-item>
              <el-dropdown-item>审核历史</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table
        v-loading="loading"
        :data="list"
        :scroll-y="{ enabled: true, gt: 0 }"
        height="auto"
        style="width: 100%"
        border
        stripe
        auto-resize
        size="mini"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{ resizable: true }"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column
          align="center"
          field="sortColumn"
          type="checkbox"
          width="50"
        >
        </vxe-column>
        <vxe-column field="paymentDate" title="收款日期"> </vxe-column>
        <vxe-column field="documentCode" title="单据编码"> </vxe-column>
        <vxe-column field="inventoryName" title="操作日期">
          <template #default="scope">
            <span>{{ $parseTime(scope.row.createdAt, "{y}-{m}-{d}") }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerNum" title="客户编码" width="180">
          <template #default="scope">
            <span>{{ scope.row.customerNum }}</span>
          </template>
        </vxe-column>
        <vxe-column field="customerName" title="客户名称"> </vxe-column>
        <vxe-column field="receivedAmount" title="金额"> </vxe-column>
        <!-- <vxe-column field="paymentDiscount" title="本次优惠"> </vxe-column> -->
        <vxe-column field="accountingName" title="经办人"> </vxe-column>
        <vxe-column field="cnName" title="录入人"> </vxe-column>
        <vxe-column field="auditUser" title="审核人"> </vxe-column>
        <vxe-column field="auditStatus" title="审核状态">
          <template #default="scope">
            <span>{{ getLabelByValue(scope.row.auditStatus) }}</span>
          </template>
        </vxe-column>
        <!-- <vxe-column field="inventoryName" title="审核批语"> </vxe-column> -->
        <vxe-column field="paymentExplain" title="收款说明"> </vxe-column>
        <vxe-column field="inventoryName" title="操作"> </vxe-column>
      </vxe-table>
    </div>
  </div>
  <contractForm ref="ContractForm" />
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { paymentList } from "@/api/newCrm";
import { findPeople } from "@/api/base";
import {
  CONTRACT_STATUS,
  CHARGE_STATUS,
  SERVICE_OPTION,
} from "@/utils/commonData";
const { proxy } = getCurrentInstance();
const list = ref([]);
const contentStyleObj = ref({});
const loading = ref(false);
const listQuery = ref({});
const statusOptions = ref([
  ...[{ label: "全部", value: 0 }],
  ...CONTRACT_STATUS,
]);
const chargeOptions = ref(CHARGE_STATUS);
const serviceOptions = ref([
  ...[{ label: "全部", value: "" }],
  ...SERVICE_OPTION,
]);
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  findPeople({ code: "bookkeeping" }).then((res) => {
    userOptions.value = res.data.data.list;
  });
  getList();
});
const getList = () => {
  paymentList(listQuery.value).then((res) => {
    if (res.data.msg == "success") {
      list.value = res.data.data.list;
    }
  });
};
const getLabelByValue = (value) => {
  const status = CONTRACT_STATUS.find((item) => item.value === value);
  return status ? status.label : "未知状态";
};
</script>
<script>
export default {
  name: "chargeSign",
};
</script>
<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
:deep(.el-checkbox) {
  margin-right: 15px;
}
.style_form {
  .el-input {
    width: 147px;
  }
  .el-select {
    width: 147px;
    margin: 0;
  }
}
.filter-item {
  margin-right: 15px !important;
}
:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
