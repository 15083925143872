<template>
  <div class="home">
    <!-- 筛选 -->
    <div class="top_select">
      <div>
        <el-button icon="Refresh" plain type="primary" size="small" class="mr-5" @click="getList()"><span style="margin-top: 2px;">刷新</span></el-button>
        <el-input
          placeholder="请输入公司名称"
          v-model="listQuery.customerName"
          style="width: 200px"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button
          type="primary"
          @click="getList()"
          size="small"
          icon="Search"
          style="margin: 0 5px"
          >搜索</el-button
        >
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin: 11px 0px 0px 15px; width: 320px"
            class="styleForm"
            label-width="85px"
          >
            <el-form-item label="销售经理：">
              <selectuser
                code="followCustom"
                v-model:userId="listQuery.userId"
                style="width: 182px; margin: 0"
              ></selectuser>
            </el-form-item>
            <el-form-item label="业务名称：">
              <selectBusiness
                v-model:category="listQuery.categoryNameId"
                style="width: 182px; margin: 0"
              ></selectBusiness>
            </el-form-item>
            <el-form-item label="意向程度：">
              <el-select
                v-model="listQuery.intentionType"
                placeholder="选择意向程度"
                style="width: 182px; margin: 0"
                filterable
                size="small"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="时间周期：">
              
            </el-form-item> -->
          </el-form>
        </search>
      </div>
      <div>
        <el-dropdown
          split-button
          type="primary"
          @click="addFollowCustomer"
          size="small"
          style="margin-right: 12px"
          :hide-on-click="false"
          v-if="$buttonStatus('gjkh_xzgjkh')"
        >
          <i class="iconfont icon-jiahao"></i> 新增客户
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <span
                  style="
                    width: 100px;
                    display: inline-block;
                    text-align: center;
                  "
                  @click="daoruFormVisible = true"
                  >导入客户</span
                >
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button
          v-if="$buttonStatus('gjkh_fp')"
          type="primary"
          size="small"
          @click="clickAllot"
          icon="Share"
          plain
          >分配</el-button
        >
        <el-button
          v-if="$buttonStatus('gjkh_sc')"
          type="danger"
          size="small"
          @click="delAll()"
          icon="Delete"
          plain
          >删除</el-button
        >
        <el-button
          type="success"
          size="small"
          plain
          icon="FolderOpened"
          :disabled="!$buttonStatus('gjkh_dc')"
          @click="daochu"
          :loading="daochuLoading"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          plain
          icon="SetUp"
          @click="toBusiness"
          :disabled="!$buttonStatus('gjkh_ywlx')"
          >业务类型</el-button
        >
      </div>
    </div>
    <!-- 客户列表 -->
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        border
        :height="contentStyleObj"
        @selection-change="handleSelectCustomer"
        v-loading="listLoading"
        stripe
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          type="index"
          label="序号"
          fixed
          width="55"
          align="center"
        />
        <el-table-column
          prop="customerName"
          label="公司名称"
          align="left"
          min-width="250"
          fixed
        >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <div
                class="item_icon"
                style="
                  width: 95%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                <span>{{ scope.row.customerName }}</span>
              </div>
              <el-tooltip
                content="复制"
                placement="top"
                v-if="scope.row.customerName"
                effect="dark"
              >
                <i
                  class="iconfont icon-fuzhi1"
                  style="color: var(--themeColor, #17a2b8); cursor: pointer"
                  @click="$copyComName(scope.row.customerName)"
                >
                </i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="linkman"
          label="联系人"
          min-width="80"
          align="left"
        />
        <el-table-column
          prop="linkmanTel"
          label="联系人电话"
          min-width="105"
          align="left"
        >
        </el-table-column>
        <el-table-column
          prop="categoryName"
          label="服务项目"
          min-width="90"
          align="left"
        />
        <el-table-column
          prop="intentionType"
          label="意向程度"
          min-width="90"
          align="left"
        />
        <!-- <el-table-column
          prop="address"
          label="公司地址"
          min-width="120"
          align="left"
        /> -->
        <el-table-column
          prop="trackStarts"
          label="跟进记录"
          min-width="180"
          align="left"
          show-overflow-tooltip="false"
        >
          <template #default="scope">
            <span
              style="color: var(--themeColor, #17a2b8); cursor: pointer"
              @click="getStatusList(scope.row)"
              v-if="scope.row.trackStarts"
              ><el-icon style="margin-right: 5px"><chat-dot-round /></el-icon
              >{{ scope.row.trackStarts }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="trackTime"
          label="跟进时间"
          min-width="140"
          align="left"
          show-overflow-tooltip="false"
        >
          <template #default="scope">
            {{ scope.row.trackTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="nextTime"
          label="下次跟进时间"
          width="100"
          align="left"
          show-overflow-tooltip="false"
        >
          <template #default="scope">
            {{ scope.row.nextTime }}
          </template>
        </el-table-column>
        <el-table-column
          prop="disCodes"
          label="销售经理"
          width="120"
          align="left"
        />
        <el-table-column
          prop="remark"
          label="备注"
          width="100"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          min-width="370"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              :disabled="!$buttonStatus('gjkh_gxgjzt')"
              type="text"
              plain
              size="small"
              @click="updateStatus(scope.row)"
              icon="ChatLineSquare"
              style="padding: 5px 7px"
              >添加记录</el-button
            >
            <el-button
              :disabled="!$buttonStatus('gjkh_bj')"
              type="text"
              plain
              size="small"
              @click="updateCustomer(scope.row)"
              icon="Edit"
              style="margin: 0; padding: 5px 7px"
              >编辑</el-button
            >
            <el-button
              :disabled="!$buttonStatus('gjkh_fp')"
              type="text"
              size="small"
              @click="allotEvery(scope.row)"
              icon="Share"
              plain
              style="margin: 0; padding: 5px 7px"
              >分配</el-button
            >
            <el-button
              :disabled="!$buttonStatus('gjkh_zdhz')"
              type="text"
              size="small"
              @click="moveCoopCustomer(scope.row)"
              icon="Connection"
              plain
              style="margin: 0; padding: 5px 7px"
              >签约合作</el-button
            >
            <el-button
              :disabled="!$buttonStatus('gjkh_sc')"
              type="text"
              size="small"
              @click="delCustomer(scope.row)"
              icon="Delete"
              plain
              style="margin: 0; color: #f56c6c; padding: 5px 7px"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="foot">
        <div></div>
        <div class="pagination" style="margin-top: 0">
          <qzf-pagination
            v-show="total > 0"
            :total="total"
            v-model:page="listQuery.page"
            v-model:limit="listQuery.limit"
            @pagination="getList()"
            type="followCustom"
          />
        </div>
      </div>
      <!-- 新增和修改跟进客户 -->
      <el-dialog
        :close-on-click-modal="false"
        v-model="followCustomerVisible"
        :title="textMap[dialogStatus]"
        width="500px"
      >
        <!-- 新增修改客户表单 -->
        <div class="content">
          <el-form
            ref="dataForm"
            :model="form"
            :rules="rules"
            label-width="120px"
            size="small"
          >
            <el-form-item label="公司名称">
              <el-input
                v-model="form.customerName"
                placeholder="请输入公司名称"
                style="width: 182px"
              />
            </el-form-item>
            <el-form-item label="意向程度" prop="intentionType">
              <selectIntention
                v-model:type="form.intentionType"
                style="width: 182px; margin: 0"
              ></selectIntention>
            </el-form-item>
            <el-form-item label="服务项目" prop="categoryNameId">
              <selectBusiness
                v-model:category="form.categoryNameId"
                style="width: 182px; margin: 0"
                ref="selectBusiness"
              ></selectBusiness>
              <span
                style="
                  margin-left: 4px;
                  cursor: pointer;
                  color: var(--themeColor, #17a2b8);
                "
                @click="toBusiness"
                >+业务类型</span
              >
            </el-form-item>
            <el-form-item label="联系人" prop="linkman">
              <el-input
                v-model="form.linkman"
                placeholder="请输入联系人"
                style="width: 182px"
              />
            </el-form-item>
            <el-form-item label="电话" prop="linkmanTel">
              <el-input
                v-model="form.linkmanTel"
                placeholder="请输入电话"
                style="width: 182px"
              />
            </el-form-item>
            <el-form-item label="地址">
              <el-input
                v-model="form.address"
                placeholder="请输入地址"
                style="width: 182px"
              />
            </el-form-item>
            <el-form-item label="销售经理" prop="userId">
              <business
                code="followCustom"
                v-model:userId="form.userId"
                style="margin: 0; width: 182px"
              ></business>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input
                v-model="form.remark"
                placeholder="请输入备注"
                style="width: 182px"
              />
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="followCustomerVisible = false" size="small"
              >取 消</el-button
            >
            <el-button type="primary" size="small" @click="createData()">
              确 定
            </el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 批量分配弹窗 -->
      <el-dialog
        :close-on-click-modal="false"
        v-model="allotFormVisible"
        title="分配"
        width="40%"
      >
        <span>销售经理：</span>
        <business code="followCustom" v-model:userId="value"></business>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="allotFormVisible = false" size="small"
              >取消</el-button
            >
            <el-button type="primary" @click="saveAllot()" size="small">
              确定
            </el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 填写跟进进度 -->
      <el-dialog
        :close-on-click-modal="false"
        v-model="statusFormVisible"
        title="添加记录"
      >
        <div>
          <div class="record">跟进进度：</div>
          <div style="margin: 10px 0; padding-left: 13px; display: flex">
            <span style="color: red">*</span>记录：<el-input
              v-model="statusForm.trackStarts"
              :rows="5"
              type="textarea"
              style="width: 70%"
            />
          </div>
          <div class="record">下次跟进时间：</div>
          <div style="margin-top: 10px; padding-left: 13px; display: flex">
            <span style="color: red">*</span>日期：
            <el-date-picker
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="请选择日期"
              size="small"
              v-model="statusForm.nextTime"
              :disabled-date="disabledDate"
            />
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="statusFormVisible = false" size="small"
              >取消</el-button
            >
            <el-button type="primary" @click="submitStatus()" size="small">
              确定
            </el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        v-model="daoruFormVisible"
        title="导入"
        height="50vh"
      >
        <el-form size="small" label-position="right" label-width="5px">
          <el-form-item label="">
            <el-input
              style="width: 60%; margin-right: 10px"
              v-model="path"
            ></el-input>
            <input
              size="small"
              ref="excel-upload-input"
              class="excel-upload-input"
              style="display: none"
              type="file"
              accept=".xlsx, .xls, .xml"
              @change="handleClick"
            />
            <el-button type="primary" @click="handleUpload()" size="small"
              >请选择文件</el-button
            >
          </el-form-item>
        </el-form>
        <div>
          <a href="javascript:;" @click="loadForm()">下载模板</a>
          <div style="text-align: right">
            <el-button
              size="small"
              type="primary"
              @click="uploadExcel"
              :loading="loading"
              >上传</el-button
            >
          </div>
        </div>
      </el-dialog>
      <status ref="status"></status>
      <daoruDialog ref="daoruDialog" />
      <signContract ref="signContract" @success="getList" />
    </div>
  </div>
  <el-drawer title="业务类型" v-model="visible" destroy-on-close size="60%">
    <businessType ref="businessTypee" @success="freshCate" />
  </el-drawer>
</template>

<script>
import {
  updateCustomer,
  addCustomer,
  saveStatus,
  delCustomerNew,
  batchDelCustomerNew,
  saveCustomerState,
  importFollowCustomer,
  saveCustomerNew,
  customerListNew,
  exportCustomerNew,
} from "@/api/crm";
import selectBusiness from "../components/selectBusiness.vue";
import business from "./components/business.vue";
import status from "./components/status.vue";
import daoruDialog from "./components/daoruDialog.vue";
import signContract from "./components/signContract.vue";
import selectIntention from "./components/selectIntention.vue";
import businessType from "../crmSettimg/businessType.vue";
export default {
  name: "followCustom",
  components: {
    selectBusiness,
    business,
    status,
    daoruDialog,
    signContract,
    selectIntention,
    businessType,
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      disabledDate(time) {
        return time.getTime() < Date.now();
      },
      loading: false,
      followCustomerVisible: false,
      statusFormVisible: false,
      statusForm: {
        trackStarts: "",
        nextTime: "",
        customerId: null,
      },
      sels: [],
      listLoading: false,
      dialogStatus: "",
      textMap: {
        update: "编辑意向客户",
        create: "新增意向客户",
      },
      listQuery: {
        page: 1,
        limit: 20,
        customerType: "1",
        customerName: null,
      },
      id: "",
      list: [],
      preList: {},
      total: "",
      value: [],
      options: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "A类意向",
          value: "A类意向",
        },
        {
          label: "B类意向",
          value: "B类意向",
        },
        {
          label: "C类意向",
          value: "C类意向",
        },
        {
          label: "D类意向",
          value: "D类意向",
        },
        {
          label: "E类意向",
          value: "E类意向",
        },
      ],
      allotFormVisible: false,
      form: {
        id: "",
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "1",
        categoryNameId: null,
        intentionType: "",
      },
      rules: {
        intentionType: [
          { required: true, message: "请选择意向程度", trigger: "change" },
        ],
        userId: [
          { required: true, message: "请选择业务负责人", trigger: "change" },
        ],
        linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        linkmanTel: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
        categoryNameId: [
          { required: true, message: "请选择服务项目", trigger: "change" },
        ],
      },
      daoruFormVisible: false,
      rawFile: "",
      path: "",
      contentStyleObj: "",
      visible: false,
      daochuLoading: false,
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(230);
  },
  mounted() {
    this.listQuery.limit = localStorage.getItem("followCustom")
      ? localStorage.getItem("followCustom") * 1
      : 20;
    this.initBus();
    this.getList();
  },

  methods: {
    // 重置
    cancel() {
      let originLimit = this.listQuery.limit;
      this.listQuery = {
        page: 1,
        limit: originLimit,
        userId: null,
        customerType: "1",
        customerName: null,
        categoryNameId: null,
        intentionType: "",
      };
      this.getList();
    },
    initBus() {
      this.$bus.off("businessTypeUpdate");
      this.$bus.on("businessTypeUpdate", (val) => {
        this.getList();
      });
    },
    //跟进客户信息
    getList() {
      this.listLoading = true;
      customerListNew(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.list = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    //新增
    addFollowCustomer() {
      this.resetForm();
      this.dialogStatus = "create";
      this.followCustomerVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    //修改客户
    updateCustomer(row) {
      this.form = Object.assign({}, row);
      // this.form.category = [];
      // this.form.category[0] = this.form.caType;
      // this.form.category[1] = this.form.caName;
      this.dialogStatus = "update";
      this.followCustomerVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (!this.form.categoryNameId) {
            this.$qzfMessage("请选择服务项目", 1);
            return;
          }
          saveCustomerNew(this.form).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.followCustomerVisible = false;
              this.$qzfMessage("保存成功");
            }
          });
        }
      });
    },
    resetForm() {
      this.form = {
        customerName: "",
        userId: [this.$store.getters["user/user"].userId * 1],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "1",
        categoryNameId: null,
        intentionType: "",
      };
    },
    //添加跟进进度
    updateStatus(row) {
      this.statusForm.customerId = row.id;
      this.statusForm.trackStarts = "";
      this.statusForm.nextTime = "";
      this.statusFormVisible = true;
    },
    submitStatus() {
      if (!this.statusForm.nextTime || !this.statusForm.trackStarts) {
        this.$qzfMessage("请将信息填写完整", 1);
        return;
      }
      saveCustomerState(this.statusForm).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("添加成功");
          this.getList();
          this.statusFormVisible = false;
        }
      });
    },
    handleSelectCustomer(sels) {
      this.sels = sels;
    },
    //查看跟进进度
    getStatusList(row) {
      this.$refs.status.getStatusList(row);
    },
    //单独分配
    allotEvery(row) {
      this.allotFormVisible = true;
      this.id = row.id;
      this.value = row.userId;
    },
    clickAllot() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择客户", 1);
        return;
      }
      this.id = "";
      this.value = [];
      this.allotFormVisible = true;
    },
    //批量
    saveAllot() {
      if (this.id == "") {
        if (this.value.length == 0) {
          this.$qzfMessage("请选择销售经理", 1);
          return;
        }
        let selects = [];
        this.sels.map((item) => {
          selects.push({
            id: item.id,
            userId: this.value,
            customerType: item.customerType,
            typeName: "1",
          });
        });
        saveStatus(selects).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("分配成功");
            this.getList();
            this.allotFormVisible = false;
            this.value = [];
          }
        });
      } else {
        updateCustomer({
          id: this.id,
          userId: this.value,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("修改成功");
            this.getList();
            this.allotFormVisible = false;
            this.id = "";
            this.value = [];
          }
        });
      }
    },
    //单独删除
    delCustomer(row) {
      this.$confirm("确认删除客户吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCustomerNew({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //批量删除
    delAll() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请选择客户", 1);
        return;
      }
      this.$confirm("确认删除客户吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          batchDelCustomerNew(ids).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //转到合作
    moveCoopCustomer(row) {
      this.$refs.signContract.init(row, "add");
    },
    //批量转合作
    allotMoveCoop() {
      this.$confirm("确定移到合作客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let arr = [];
        this.sels.map((v) => {
          arr.push({
            id: v.id,
            customerType: "2",
          });
        });
        saveStatus(arr).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("修改成功");
            this.$bus.emit("coopUpdate");
            this.$bus.emit("contractUpdate");
            this.getList();
          }
        });
      });
    },
    //导入合同
    loadForm() {
      window.open(
        "https://file.listensoft.net/data/v2/other/跟进客户导入.xlsx" +
          "?" +
          new Date().getTime()
      );
    },
    handleClick(e) {
      //console.log(e);
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.loading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importFollowCustomer(param).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.getList();
          this.daoruFormVisible = false;
          this.$refs.daoruDialog.init(res.data.data);
        }
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
    //业务类型
    toBusiness() {
      this.visible = true;
    },
    freshCate() {
      this.$refs.selectBusiness?.getCategory();
    },
    //导出
    daochu() {
      this.daochuLoading = true;
      let ids = [];
      this.sels.map((v) => {
        ids.push(v.id);
      });
      let param = {
        query: this.listQuery,
        ids: ids,
      };
      exportCustomerNew(param).then((res) => {
        this.daochuLoading = false;
        if (res.data.msg == "success") {
          window.open(res.data.data.url);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}
.foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.el-form-item {
  margin-bottom: 22px !important;
}
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}
.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
</style>
